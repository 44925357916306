/* eslint-disable no-shadow */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-console */
export default function getErrors(error) {
  console.log(error);
  try {
    console.log(error?.data);

    if (error && error.data) {
      if (typeof error.data === 'string') {
        console.log(error.data);
        return error.data;
      }
      let errorString = '';
      console.log('will start loop');

      // eslint-disable-next-line no-restricted-syntax
      for (const key in error.data) {
        if (Object.prototype.hasOwnProperty.call(error.data, key)) {
          console.log(`loop ${key}`);
          const errorLine = error.data[key];
          if (typeof errorLine === 'string') errorString += `${errorLine}\n`;
          else if (Array.isArray(errorLine)) errorString += `${key}: ${errorLine.join()}\n`;
          else for (const [key, value] of Object.entries(errorLine)) errorString += `${key}: ${value}`;
          console.log(errorLine);
        }
      }
      return errorString;
    }
    return error;
  } catch (e) {
    console.log('Error inconnue, veuillez contacter le support.');
    return e;
  }
}
