import React from 'react';
import APP_PATHS from 'configs/appPaths';
import { sideBarEntries } from 'configs/sideBarEntries';
import { useLocation } from 'react-router';
import { useUserState } from 'context/UserContext';
import { Avatar } from '@axazara/raiton-atoms';

// TODO: Refactor and move to raiton
export function SideBar() {
  const location = useLocation();
  const user = useUserState();

  return (
    <aside className="bg-white w-full h-full flex justify-between flex-col py-5 pl-8 pr-2.5 shadow-l">
      <div>
        <div className="flex items-center mb-12 mt-3">
          <a href={APP_PATHS.ROOT}>
            <img src="/images/logo.png" alt="logo-mailify" className="h-14" />
          </a>
        </div>
        <nav className="flex space-y-4 flex-col">
          {sideBarEntries.map((entry) => {
            const isActive = location.pathname.includes(entry.href);
            return (
              <SideBarItem
                title={entry.title}
                key={entry.label}
                icon={entry.icon}
                href={entry.href}
                isActive={isActive}
              />
            );
          })}
        </nav>
      </div>
      <div className="border-t border-neutral-40 py-6">
        <div className="flex flex-row items-center space-x-4">
          <Avatar size="medium">{user?.full_name && user?.full_name[0]}</Avatar>
          <span className="text-base font-semibold font-poppins text-neutral-30">{user?.full_name}</span>
        </div>
      </div>
    </aside>
  );
}

function SideBarItem({ title, icon: Icon, href, isActive }) {
  return (
    <a
      href={href}
      className={`${
        isActive ? 'text-primary-40 bg-primary-90' : ''
      } flex flex-row items-center text-neutral-40 hover:text-primary-40 hover:bg-primary-90 p-3 rounded-lg font-poppins font-semibold`}
    >
      <Icon className="w-5 h-5 text-current mr-3" />
      <span>{title}</span>
    </a>
  );
}

export default SideBar;
