import { Button } from '@axazara/raiton-atoms';
import React, { useCallback, useEffect, useState } from 'react';
// libs
import { ErrorBoundary as DefaultErrorBoundary } from 'react-error-boundary';
import { Link } from 'react-router-dom';

function ErrorFallbackUI() {
  return (
    <div className="h-full w-full items-center justify-center flex">
      <div className="w-full flex items-center justify-center flex-col space-y-3">
        <div className="text-2xl font-semibold"> 😱 Oups sorry, something went wrong.</div>
        <Button type="button" component={Link} to="/">
          Reload
        </Button>
      </div>
    </div>
  );
}

export function ErrorBoundary({ children }) {
  const [boundaryKey, setBoundaryKey] = useState(0);

  const memorizedSyncLog = useCallback(async (message, componentStack) => {
    const params = {
      level: 'ERROR',
      datetime: new Date().toUTCString(),
      os: 'Window',
      description: 'platform.description',
      userId: 1,
      error: JSON.stringify(message),
      componentStack: JSON.stringify(componentStack),
      location: window.location.href,
      version: '1.0.0',
    };
    localStorage.setItem('errorLog', JSON.stringify(params));
  }, []);

  useEffect(() => {
    async function sendLogWhenOnline() {
      const getLogFromStorage = localStorage.getItem('errorLog');
      if (getLogFromStorage) {
        memorizedSyncLog();
      }
    }
    sendLogWhenOnline();

    window.onerror = async (message, _, __, ___, errorObj) => {
      memorizedSyncLog(message, errorObj?.stack);
    };

    return () => {
      window.onerror = null;
    };
  }, [memorizedSyncLog]);

  return (
    <DefaultErrorBoundary
      resetKeys={[boundaryKey]}
      FallbackComponent={ErrorFallbackUI}
      onReset={() => setBoundaryKey((prev) => prev + 1)}
    >
      <>{children}</>
    </DefaultErrorBoundary>
  );
}
