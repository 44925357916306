import { axiosInstance } from './initAxios';

class ApiHelper {
  api;

  constructor() {
    this.api = axiosInstance;
  }

  async get(url, config) {
    return this.api.get(url, config);
  }

  async post(url, data, config) {
    return this.api.post(url, data, config);
  }

  async put(url, data, config) {
    return this.api.put(url, data, config);
  }

  async delete(url, data, config) {
    return this.api.delete(url, data, config);
  }
}

export const Api = new ApiHelper();
