import * as React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Routes } from 'routes';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
// eslint-disable-next-line import/no-extraneous-dependencies
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import { Auth } from 'containers/Auth';

import 'css/tailwind.css';
import 'css/custom.css';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

export function App() {
  return (
    <Router>
      <QueryClientProvider client={queryClient}>
        <Auth>
          <Routes />
        </Auth>
        {process.env.NODE_ENV === 'development' && <ReactQueryDevtools initialIsOpen={false} />}
      </QueryClientProvider>
    </Router>
  );
}
