import * as React from 'react';
import { Redirect } from 'react-router';

// configs
import { APP_PATHS } from 'configs/appPaths';

// services
import { useUserState } from 'context/UserContext';

export function AuthGuard({ children }) {
  const { isAuthenticated } = useUserState();

  if (!isAuthenticated) return <Redirect to={APP_PATHS.LOGIN} />;

  return <>{children}</>;
}

export default AuthGuard;
