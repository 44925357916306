import { ActionsTypes } from 'actions/actions.type';
import React from 'react';
import { authService } from 'services/auth.service';
import { APP_PATHS } from 'configs/appPaths';
import getErrors from 'helpers/get-errors';

const UserStateContext = React.createContext(null);
const UserDispatchContext = React.createContext(null);

function userReducer(state, action) {
  switch (action.type) {
    case ActionsTypes.LOGIN_SUCCESS:
      return { ...state, ...action.payload, isAuthenticated: true };
    case ActionsTypes.LOGOUT:
      return { ...state, isAuthenticated: false };
    case ActionsTypes.LOGIN_FAILURE:
      return { ...state, isAuthenticated: false };
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function UserProvider({ children }) {
  const [state, dispatch] = React.useReducer(userReducer, {
    isAuthenticated: authService.isAuthenticated(),
    ...authService.getUser(),
  });

  return (
    <UserStateContext.Provider value={state}>
      <UserDispatchContext.Provider value={dispatch}>{children}</UserDispatchContext.Provider>
    </UserStateContext.Provider>
  );
}

function useUserState() {
  const context = React.useContext(UserStateContext);
  if (context === undefined) {
    throw new Error('useUserState must be used within a UserProvider');
  }
  return context;
}

function useUserDispatch() {
  const context = React.useContext(UserDispatchContext);
  if (context === undefined) {
    throw new Error('useUserDispatch must be used within a UserProvider');
  }
  return context;
}

// ###########################################################

async function login(dispatch, email, password, history, setIsLoading, setError) {
  setError(null);
  setIsLoading(true);
  try {
    const { user } = await authService.loginWithEmailPassword(email, password);
    setIsLoading(false);
    dispatch({
      type: ActionsTypes.LOGIN_SUCCESS,
      payload: { user },
    });
    history.push(APP_PATHS.ROOT);
  } catch (error) {
    setError(getErrors(error.response));
    setIsLoading(false);
    dispatch({
      type: ActionsTypes.LOGIN_FAILURE,
      payload: { error, user: null },
    });
  }
}

const logout = () => (dispatch) => {
  authService.logOut();
  dispatch({ type: ActionsTypes.LOGOUT });
};

export { UserProvider, useUserState, useUserDispatch, login, logout };
