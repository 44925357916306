import React, { Fragment, lazy, Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Spinner } from '@axazara/raiton-atoms';

// configs
import { APP_PATHS } from 'configs/appPaths';

// types

// layouts
import { MainLayout } from 'layouts';

// containers
import { AnonymousGuard, AuthGuard } from 'guards';

// route
const Login = lazy(() => import('features/auth/login'));
const Reset = lazy(() => import('features/auth/reset'));
const TwoFactor = lazy(() => import('features/auth/two-factor'));
const Invitation = lazy(() => import('features/auth/invitation'));
const Dashboard = lazy(() => import('features/dashboard'));
const MailHistory = lazy(() => import('features/mail-history'));
const Teams = lazy(() => import('features/teams'));
const Apps = lazy(() => import('features/apps'));
const AppsDetails = lazy(() => import('features/apps/details'));
const Template = lazy(() => import('features/template'));
const TemplateDetails = lazy(() => import('features/template/template-details'));
const Servers = lazy(() => import('features/servers'));

const routesConfig = [
  {
    exact: true,
    path: '/',
    component: () => <Redirect to={APP_PATHS.ROOT} />,
  },
  {
    exact: true,
    guard: AnonymousGuard,
    path: APP_PATHS.LOGIN,
    component: Login,
  },
  {
    exact: true,
    guard: AnonymousGuard,
    path: APP_PATHS.PASSWORD_RESET,
    component: Reset,
  },
  {
    exact: true,
    guard: AnonymousGuard,
    path: APP_PATHS.TWO_FACTOR_RESET,
    component: TwoFactor,
  },
  {
    exact: true,
    guard: AnonymousGuard,
    path: APP_PATHS.INVITATION,
    component: Invitation,
  },
  {
    path: '/',
    guard: AuthGuard,
    layout: MainLayout,
    routes: [
      {
        exact: true,
        path: APP_PATHS.ROOT,
        component: Dashboard,
      },
      {
        exact: true,
        path: APP_PATHS.APPS,
        component: Apps,
      },
      {
        exact: true,
        path: APP_PATHS.APPS_DETAILS,
        component: AppsDetails,
      },
      {
        exact: true,
        path: APP_PATHS.SERVERS,
        component: Servers,
      },
      {
        exact: true,
        path: APP_PATHS.HISTORY,
        component: MailHistory,
      },
      {
        exact: true,
        path: APP_PATHS.TEMPLATE,
        component: Template,
      },
      {
        exact: true,
        path: APP_PATHS.TEMPLATE_DETAILS,
        component: TemplateDetails,
      },
      {
        exact: true,
        path: APP_PATHS.TEAMS,
        component: Teams,
      },

      {
        component: () => <Redirect to={APP_PATHS.ERROR_404} />,
      },
    ],
  },
  {
    path: '*',
    routes: [
      {
        exact: true,
        path: '/admin',
        component: MainLayout,
      },
      {
        component: () => <Redirect to={APP_PATHS.ERROR_404} />,
      },
    ],
  },
];

const renderRoutes = (routes) => {
  return (
    <>
      {routes ? (
        <Suspense
          fallback={
            <div className="h-screen text-green-400 grid place-items-center">
              <Spinner size="large" />
            </div>
          }
        >
          <Switch>
            {routes.map((route) => {
              const Guard = route.guard || Fragment;
              const Layout = route.layout || Fragment;
              const Component = route.component;

              return (
                <Route
                  key={`routes-${route.path}`}
                  path={route.path}
                  exact={route.exact}
                  render={(props) => (
                    <Guard>
                      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                      <Layout>{route.routes ? renderRoutes(route.routes) : <Component {...props} />}</Layout>
                    </Guard>
                  )}
                />
              );
            })}
          </Switch>
        </Suspense>
      ) : null}
    </>
  );
};

export function Routes() {
  return renderRoutes(routesConfig);
}

export default Routes;
