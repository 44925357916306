import React, { useEffect } from 'react';
// services
import { authService } from 'services/auth.service';

import { useUserDispatch } from 'context/UserContext';

export function Auth({ children }) {
  const dispatch = useUserDispatch();

  useEffect(() => {
    function initAuth() {
      authService.handleAuthentication();
    }
    initAuth();
  }, [dispatch]);

  return <>{children}</>;
}

export default Auth;
