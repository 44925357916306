function getEnvConfig({ preset, dev, beta, prod }) {
  const currentEnv = process.env.REACT_APP_VERCEL_ENV || 'dev';
  switch (currentEnv) {
    case 'prod':
    case 'production':
      if (prod !== undefined) return prod;
      break;

    case 'beta':
    case 'preview':
    case 'staging':
      if (beta !== undefined) return beta;
      break;

    case 'dev':
    case 'development':
      if (dev !== undefined) return dev;
      break;

    default:
      break;
  }

  if (preset !== undefined) return preset;

  throw new Error(`Unable to find config value for env=${currentEnv}`);
}

export const appConfig = {
  MAILIFY_API_DOMAIN: getEnvConfig({
    dev: 'https://mailify.axaship.com',
    beta: 'https://mailify.axaship.com',
    prod: 'https://mailify.axazara.io',
  }),
};
