import React from 'react';
import ReactDOM from 'react-dom/client';
import { App } from 'app';
import { NotificationsProvider, ModalsProvider } from '@axazara/raiton-molecules';

import { UserProvider } from 'context/UserContext';

const root = ReactDOM.createRoot(document.getElementById('mailify-ui'));
root.render(
  <React.StrictMode>
    <NotificationsProvider>
      <ModalsProvider>
        <UserProvider>
          <App />
        </UserProvider>
      </ModalsProvider>
    </NotificationsProvider>
  </React.StrictMode>,
);
