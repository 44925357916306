/* eslint-disable no-return-await */
/* eslint-disable no-param-reassign */
/* eslint-disable no-underscore-dangle */
import axios from 'axios';
import { APP_PATHS } from 'configs/appPaths';
import storageKeys from 'configs/storageKeys';

const { appConfig } = require('configs/app.config');

function getAccessToken() {
  const accessToken = window.localStorage.getItem(storageKeys.token);
  return accessToken;
}

function getRefreshToken() {
  return window.localStorage.getItem(storageKeys.refresh);
}

const requestConfig = {
  baseURL: appConfig.MAILIFY_API_DOMAIN,
  showSpinner: false,
};

export const axiosInstance = axios.create(requestConfig);

axiosInstance.interceptors.request.use(
  async (config) => {
    const accessToken = getAccessToken();
    if (accessToken) {
      // eslint-disable-next-line no-param-reassign
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

axiosInstance.interceptors.response.use(
  (res) => {
    return res.data;
  },
  async (error) => {
    // access token expired
    if (error?.response?.status === 401 && !error.config._retry) {
      if (!getRefreshToken()) {
        return Promise.reject(error);
      }

      error.config._retry = true;
      try {
        const result = await axiosInstance.get('/auth/refresh/');
        window.localStorage.setItem(storageKeys.token, result.authorization.token);
        axiosInstance.defaults.headers.common.Authorization = `Bearer ${result.authorization.token}`;

        return await axiosInstance(error.config);
      } catch (err) {
        window.localStorage.clear();
        window.location.pathname = APP_PATHS.LOGIN;
        return await Promise.reject(err.response);
      }
    }

    // handle errors
    switch (error.response?.status) {
      case 400: {
        break;
      }
      case 500: {
        break;
      }
      default:
        break;
    }
    return Promise.reject(error);
  },
);
