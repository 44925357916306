import React from 'react';
import { ErrorBoundary } from 'containers/ErrorBoundary';
import { SideBar } from './SideBar';

export function MainLayout({ children }) {
  return (
    <div className="grid grid-cols-[minmax(270px,305px)_1fr] h-screen overflow-hidden bg-neutral">
      <SideBar />

      <main className="overflow-y-auto px-9">
        <ErrorBoundary>{children}</ErrorBoundary>
      </main>
    </div>
  );
}

export default MainLayout;
