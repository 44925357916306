import { ClockOutline, DocumentTextOutline, Grid2Outline, Home2Outline, PeopleOutline } from '@axazara/raiton-icons';
// configs
import { APP_PATHS } from 'configs/appPaths';

export const sideBarEntries = [
  {
    title: 'Dashboard',
    href: APP_PATHS.ROOT,
    icon: Home2Outline,
    label: 'Dashboard',
  },
  {
    title: 'Servers',
    icon: DocumentTextOutline,
    href: APP_PATHS.SERVERS,
    label: 'SERVERS',
  },

  {
    title: 'Apps',
    icon: Grid2Outline,
    href: APP_PATHS.APPS,
    label: 'Apps',
  },

  {
    title: 'Template',
    icon: DocumentTextOutline,
    href: APP_PATHS.TEMPLATE,
    label: 'Template',
  },
  {
    title: 'History',
    icon: ClockOutline,
    href: APP_PATHS.HISTORY,
    label: 'History',
  },
  {
    title: 'Team',
    href: APP_PATHS.TEAMS,
    icon: PeopleOutline,
    label: 'Team',
  },
];

export default sideBarEntries;
