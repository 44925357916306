export const APP_PATHS = {
  ROOT: '/admin',
  LOGIN: '/auth/login',
  PASSWORD_RESET: '/auth/password-reset',
  TWO_FACTOR_RESET: '/auth/two-factor/:token',
  INVITATION: '/auth/invitation/:key',
  APPS: '/apps',
  APPS_DETAILS: '/apps/:id',
  SERVERS: '/servers',
  SERVERS_DETAILS: '/servers/:id',
  TEMPLATE: '/template',
  TEMPLATE_DETAILS: '/template/:id',
  HISTORY: '/history/mail-history',
  HISTORY_DETAILS: '/history/:id',
  TEAMS: '/teams',
  TEAM_DETAILS: '/team/:id',
  ERROR_404: '/404',
};
export default APP_PATHS;
