import storageKeys from 'configs/storageKeys';
import { Api } from './api.service';

class AuthService {
  handleAuthentication = () => {
    const accessToken = this.getAccessToken();
    if (!accessToken) return;
    this.setSession('access', accessToken);
  };

  loginWithEmailPassword = async (email, password) => {
    const data = await Api.post('/auth/login/', { email, otp_code: password });
    this.setSession(storageKeys.token, data.authorization.token);
    this.setSession(storageKeys.refresh, data.refresh);
    this.setSession(storageKeys.tokenValidity, new Date().getTime() + data.authorization.expires_in - 15);
    const accountStringify = JSON.stringify(data.staff);
    this.setSession(storageKeys.account, accountStringify);
    return {
      access: data.authorization.token,
      user: data.staff,
    };
  };

  setSession = (key, access) => {
    localStorage.setItem(key, access);
  };

  logOut = () => {
    localStorage.clear();
  };

  getUser = () => {
    const account = localStorage.getItem(storageKeys.account) || '0';
    return JSON.parse(account);
  };

  getAccessToken = () => localStorage.getItem(storageKeys.token);

  isAuthenticated = () => !!this.getAccessToken();
}

export const authService = new AuthService();
