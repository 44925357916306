import React from 'react';
import { Redirect } from 'react-router';

// configs
import { APP_PATHS } from 'configs/appPaths';

// services
import { useUserState } from 'context/UserContext';

function AnonymousGuard({ children }) {
  const { isAuthenticated } = useUserState();

  if (isAuthenticated) return <Redirect to={APP_PATHS.ROOT} />;

  return <>{children}</>;
}

export default AnonymousGuard;
